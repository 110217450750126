export enum ETPScheduleType {
    FEDERAL = 'FEDERAL',
    STATE = 'STATE'
}

export enum ETPScheduleStatus {
    PROPOSED = 'PROPOSED',
    DECLINED = 'DECLINED',
    ACCEPTED = 'ACCEPTED'
}

export enum ETPPaymentStatus {
    PROPOSED = 'PROPOSED',
    DECLINED = 'DECLINED',
    SCHEDULED = 'SCHEDULED',
    SUSPENDED = 'SUSPENDED',
    PENDING = 'PENDING',
    COMPLETED = 'COMPLETED'
}

export enum ETPSubscriptionStatus {
    PROPOSED = 'PROPOSED',
    OFFER_SEEN = 'OFFER_SEEN',
    CALL_REQUESTED = 'CALL_REQUESTED',
    PAYMENT_PENDING = 'PAYMENT_PENDING',
    DECLINED = 'DECLINED',
    ACTIVE = 'ACTIVE',
    TERMINATED = 'TERMINATED'
}