import {CreateETPSubscriptionPayload} from "@/dto/taxation/individual/estimated/CreateETPSubscriptionPayload";
import axios from "axios";
import {TAXATION_INDIVIDUAL_ETP_ENDPOINT, TAXATION_INDIVIDUAL_ETP_OFFER_ENDPOINT, TAXATION_INDIVIDUAL_ETP_PAYMENTS_ENDPOINT} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";
import QueryUtils from "@/utils/QueryUtils";
import Page from "@/dto/taxation/Page";
import ETPSubscriptionDTO from "@/dto/taxation/individual/estimated/ETPSubscriptionDTO";
import ETPOfferDTO from "@/dto/taxation/individual/estimated/ETPOfferDTO";
import {ETPSubscriptionStatus} from "@/constants/IndividualTaxationConstants";
import ETPPaymentDTO from "@/dto/taxation/individual/estimated/ETPPaymentDTO";
import UpdateETPSubscriptionPayload from "@/dto/taxation/individual/estimated/UpdateETPSubscriptionPayload";

class ETPService {

    createSubscription(payload: CreateETPSubscriptionPayload) {
        return axios.post(TAXATION_INDIVIDUAL_ETP_ENDPOINT, payload, {headers: authHeader()});
    }

    getSubscriptions(pageNumber: number, pageSize: number) {
        const queryString = QueryUtils.buildQueryString(
            ["pageNumber", pageNumber],
            ["pageSize", pageSize]
        )
        return axios.get<Page<ETPSubscriptionDTO>>(TAXATION_INDIVIDUAL_ETP_ENDPOINT + queryString, {headers: authHeader()});
    }

    getSubscription(id: number) {
        return axios.get<ETPSubscriptionDTO>(`${TAXATION_INDIVIDUAL_ETP_ENDPOINT}/${id}`, {headers: authHeader()});
    }

    getOffer(id: string) {
        return axios.get<ETPOfferDTO>(`${TAXATION_INDIVIDUAL_ETP_OFFER_ENDPOINT}/${id}`);
    }

    updateSubscription(id: number, payload: UpdateETPSubscriptionPayload) {
        return axios.put(`${TAXATION_INDIVIDUAL_ETP_ENDPOINT}/${id}`, payload, {headers: authHeader()});
    }

    updatePayment(payment: ETPPaymentDTO){
        return axios.put(`${TAXATION_INDIVIDUAL_ETP_PAYMENTS_ENDPOINT}/${payment.id}`, payment, {headers: authHeader()});
    }

    getPayment(id: number){
        return axios.get<ETPPaymentDTO>(`${TAXATION_INDIVIDUAL_ETP_PAYMENTS_ENDPOINT}/${id}`, {headers: authHeader()});
    }

    requestCall(id: string, accompanyingText: string, phone: string) {
        return axios.put(`${TAXATION_INDIVIDUAL_ETP_OFFER_ENDPOINT}/${id}/call`, {accompanyingText: accompanyingText, phone: phone});
    }

    accountPayment(paymentId: number, bankAccountId: number){
        return axios.put(`${TAXATION_INDIVIDUAL_ETP_PAYMENTS_ENDPOINT}/${paymentId}/account`, {value: bankAccountId}, {headers: authHeader()});
    }

    cardPayment(paymentId: number){
        return axios.put(`${TAXATION_INDIVIDUAL_ETP_PAYMENTS_ENDPOINT}/${paymentId}/card`, null, {headers: authHeader()});
    }

}

export default new ETPService();