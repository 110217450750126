






























import {Component, Vue} from 'vue-property-decorator';
import ETPOfferDTO, {ETPOfferPaymentDTO} from "@/dto/taxation/individual/estimated/ETPOfferDTO";
import {namespace} from "vuex-class";
import ETPService from "@/services/ETPService";
import {ETPScheduleType, ETPSubscriptionStatus} from "@/constants/IndividualTaxationConstants";
import PortalTextarea from "@/components/common/PortalTextarea.vue";
import {ifValid} from "@/utils/ComponentUtils";
import PhoneInput from "@/components/util/PhoneInput.vue";

const AppModule = namespace("App");

@Component({
  components: {PhoneInput, PortalTextarea},
  computed: {
    ETPSubscriptionStatus() {
      return ETPSubscriptionStatus
    }
  }
})
export default class ETPOffer extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  offer: ETPOfferDTO | null = null;

  accompanyingText = "";
  phone: string | null = null;

  mounted() {
    this.loadOffer();
  }

  loadOffer() {
    this.startLoading();
    ETPService.getOffer(this.id).then(
        ok => {
          this.offer = ok.data;
          this.stopLoading();
        },
        err => {
          console.log(JSON.stringify(err));
          this.stopLoading();
        }
    )
  }

  get id(): string {
    return this.$route.params.id;
  }

  getPaymentText(p: ETPOfferPaymentDTO): string {
    switch (p.type){
      case ETPScheduleType.FEDERAL: return `Federal tax`
      case ETPScheduleType.STATE: return `${p.state} state tax`
    }
  }

  requestCall(){
    ifValid(this, () => {
      this.startLoading();
      ETPService.requestCall(this.id, this.accompanyingText, this.phone as string).then(
          ok => {
            this.stopLoading();
            this.loadOffer();
          },
          err => {
            this.stopLoading();
            console.log(JSON.stringify(err));
          }
      )
    })
  }

}
